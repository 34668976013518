.projectView {
    background-color: white;
    min-width: 800px;
    width: calc(100% - 380px);
    height: calc(100vh - 75px);
    padding: 10px;
    padding-top: 0px;
    margin-left: 10px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: auto;
    border: 10px solid transparent;
    border-radius: 20px;
    display: inline-block;
}

.projectView.sbOpen {
    width: calc(100% - 650px);
    margin-left: 280px;
}

::-webkit-scrollbar             { width: 10px; }
::-webkit-scrollbar-track       { background: #d6d6d6; border-radius: 5px; }
::-webkit-scrollbar-thumb       { 
    background: #888888; 
    border: 3px solid transparent;
    border-radius: 9px; 
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover { 
    background: #555555;    
    border: 2px solid transparent;
    border-radius: 9px; 
    background-clip: content-box; 
}

.outputUpload {
    width: 360px;
    height: calc(100vh - 75px);
    display: inline-block;
    vertical-align: top;
}

.outputListView {
    background-color: white;
    width: 350px;
    height: calc(100vh - 375px);
    padding-top: 0px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    border: 10px solid transparent;
    border-radius: 20px;
    margin-left: 10px;
    display: block;
}

.uploadView {
    background-color: white;
    width: 350px;
    height: 290px;
    padding-top: 0px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: none;
    border: 10px solid transparent;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 10px;
    display: block;
}


.projectname {
    position: fixed;
    top: -27px;
    left: 50%;
    z-index: 1002;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 20px;
    color: white;
}

.projectname h1 {
    display: inline-block;
}

.projectname button {
    margin: 0 10px;
    width: 35px;
    height: 35px;
    padding: 5px;
    color: #9b9b9b;
    background-color: inherit;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.projectname button:hover {
    background-color: inherit !important;
    color: white;
}

.projectname button span {
    font-size: 25px;
}


/* Hamburger button */
.sbHamburger {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    padding: 12px 12px;
    color: white;
    width: 30px;
    height: 30px;
}
.sbHambBar1, .sbHambBar2, .sbHambBar3 {
    width: 30px;
    height: 4px;
    background-color: white;
    border-radius: 2px;
    transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    margin: 5px 0;
}
.sbHambBar1.sbBarOpen { transform: translate(0, 9px) rotate(-45deg); -webkit-transform: translate(0, 9px) rotate(-45deg); }
.sbHambBar2.sbBarOpen { opacity: 0; }
.sbHambBar3.sbBarOpen { transform: translate(0, -9px) rotate(45deg); -webkit-transform: translate(0, -9px) rotate(45deg); }


/* Buttons */
button, label.bigButton, .button {
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--accent-color);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    line-height: normal;
    text-decoration: none;
}

button:hover, label.bigButton:hover, .button:hover {
    background-color: #4d6ca5;
}

button:disabled, button:disabled:hover, button:disabled:active, label.disabled, label.disabled:hover, label.disabled:active {
    color: white;
    background-color: #b6b6b6;
    cursor: default;
}

.hugeButton {
    padding: 20px 30px;
    margin: 18px 20px;
    font-size: 12pt;
    border-radius: 15px;
}

.bigButton {
    padding: 10px 20px;
    margin: 8px 0;
}

.smallButton {
    padding: 3px 7px;
    margin: 0 2px;
}

.darkButton       { 
    background-color: #00426e !important; 
}

.darkButton:hover { 
    background-color: #b6c1d6 !important; 
}

.iconButton {
    font-size: 20px;
    padding: 6px;
    line-height: 0.7;
    vertical-align: top;
    border-radius: 16px;
}

.helpButton {
    padding: 5px;
    color: #ffffff;
    background-color: #ffc273 !important; 
    position: absolute;
    right: 5px;
    top: 5px;
}

.helpButton:hover { 
    color: #ffffff;
    background-color: #ffa228 !important; 
}

.helpButton .material-symbols-outlined {
    font-size: 15px;
}

.helpButton.floatRight {
    position: revert;
    float: right;
    margin-right: 5px;
}

.helpButton.floatLeft {
    position: revert;
    float: left;
    margin-left: 5px;
}


.shadow {
    box-shadow:
        0px 0px 2.2px rgba(0, 0, 0, 0.037),
        0px 0px 5.3px rgba(0, 0, 0, 0.053),
        0px 0px 9.9px rgba(0, 0, 0, 0.065),
        0px 0px 17.6px rgba(0, 0, 0, 0.077),
        0px 0px 33px rgba(0, 0, 0, 0.093),
        0px 0px 79px rgba(0, 0, 0, 0.13)
    ;
}
