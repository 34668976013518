.overviewView {
    background-color: white;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: auto;
    max-width: 850px;
    margin: auto;
    padding: 10px;
    padding-top: 0px;
    height: calc(100vh - 75px);
    border: 10px solid transparent;
    border-radius: 20px;
}

.overviewPage {
    max-width: 800px;
    padding: 0 100px;
    margin: auto;
}

.overviewPage table {
    border-collapse: collapse;
    width: 550px;
}

.overviewPage table h1 .material-symbols-outlined {
    font-size: 30px;
    vertical-align: middle;
}
.overviewPage table h1 .text {
    font-size: 25px;
    vertical-align: middle;
    padding-left: 15px;
}

.overviewPage table tr { width: 100%; }
.overviewPage table .dataRow { background-color: white; }
.overviewPage table .dataRow:hover { background-color: rgb(233, 233, 233); }
.overviewPage table .dataRow td:first-child {
    font-weight: bold;
    margin: 5px 0;
    text-align: right;
    padding: 5px;
    width: 10%;
    border-radius: 7px;
    text-align: center;
    background-color: #dbe8ff;
}
.overviewPage table .dataRow td:not(:first-child) {
    height: 30px;
    width: 90%;
    padding: 0;
    border-radius: 7px;
}
.overviewPage table .spaceRow { height: 5px; }

.overviewPage table .dataRow td span{
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    height: calc(100%);
    width: calc(100% - 10px);
    border: none;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
