.loginPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../images/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.loginDiv {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow:
        2px 2px 1.4px rgba(0, 0, 0, 0.096),
        4.7px 4.9px 3.5px rgba(0, 0, 0, 0.137),
        8.9px 9.1px 6.5px rgba(0, 0, 0, 0.17),
        15.9px 16.3px 11.6px rgba(0, 0, 0, 0.203),
        29.7px 30.5px 21.7px rgba(0, 0, 0, 0.244),
        71px 73px 52px rgba(0, 0, 0, 0.34)
    ;
    position: relative;
}

.loginLogo {
    height: 100px;
    display: block;
    margin: auto;
    padding: 10px 0px 20px 0px;
}

.loginInput {
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: bold;
}

.loginFail {
    padding: 12px 20px;
    margin: 8px 0 0;
    border: 1px solid red;
    border-radius: 4px;
    color: red;
    font-size: 14px;
    background-color: rgba(255, 0, 0, 0.137);
}

.homeDiv {
    padding: 0px 20px;
}

.logoutDiv {
    padding: 0px 10px;
    position: fixed;
    top: 0;
    right: 0;
}

.header {
    background-color: #006FB7;
    height: 55px;
    color: white;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
}

.header label {
    padding: 0 10px;
    line-height: 50px;
}

.photomation {
    margin: 0;
}

.powered {
    margin: 10px 0 0 0;
    color: #929292;
}

.powered a:link{
    text-decoration: none;
}

.mapixLogo {
    font-family: 'Catamaran', sans-serif;
    font-weight: 900;
    color: #2A252C;
    background-color: var(--accent-color);
    padding: 5px 7px 7px 7px;
    border-radius: 5px;
    margin: 0 0 0 5px;
}

.userPopup-content {
    z-index: 1001 !important;
}

.userPopup-content .button {
    padding: 5px;
    width: calc(100% - 10px);
    text-align: center;
    margin: 2px 0px;
}

.loginButton {
    float: right;
}

.createButton {
    padding: 10px 10px !important;
    background-color: white;
    color: grey;
}

.createButton:hover {
    background-color: grey;
    color: white;
}