.smallPopup-content, .confirmPopup-content {
    width: 250px;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
}

.smallPopup-content .bigButton, .confirmPopup-content .bigButton {
    margin: 0px 10px;
}

.mediumPopup-content {
    width: 500px;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
}

.popup-content {
    box-shadow:
        0.9px 1px 1.4px rgba(0, 0, 0, 0.096),
        2.1px 2.3px 3.5px rgba(0, 0, 0, 0.137),
        4px 4.4px 6.5px rgba(0, 0, 0, 0.17),
        7.1px 7.8px 11.6px rgba(0, 0, 0, 0.203),
        13.4px 14.6px 21.7px rgba(0, 0, 0, 0.244),
        32px 35px 52px rgba(0, 0, 0, 0.34)
    ;
}

.progressBar {
    background-color: lightgray;
    height: 12px;
    width: 90%;
    padding: 4px;
    margin: auto;
    border-radius: 10px;
}

.progressBar .progress {
    background-color: var(--accent-color);
    height: 12px;
    border-radius: 6px;
}


.gifPopup-content {
    width: 800px !important;
    height: 450px;
    z-index: 1002 !important;
}
  
.gifPopup-content img {
    width: 100%;
}