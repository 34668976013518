.uploadHeader {
    height: 40px;
    font-size: 16px;
    font-weight: bold;
}

.uploadList {
    height: calc(100% - 80px);
    overflow: auto;
}

.uploadAdd {
    height: 40px;
    overflow: auto;
}

.uploadAdd .info {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.uploadAdd .bigButton {
    vertical-align: middle;
    padding: 5px 10px;
    margin: 5px 5px;
}

.fileRow {
    margin-top: 3px;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 15px;
}
.fileRow:hover { background-color: rgb(219, 219, 219); }

.uploadState {
    display: block;
    width: 23px;
    min-height: 5px;
    text-align: center;
    float: left;
}

.uploadState span   { font-size: 17px; padding: 3px; }
.uploadStateDone    { color: green; }
.uploadStateRunning { color: #5392ff; animation: rotation 3s infinite linear; }

.uploadName {
    display: inline-block;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 73px);
}

.uploadBtn {
    float: right;
    margin-top: 1px;
}

.uploadFileBtn {
    height: 23px;
    width: 23px;
    padding: 2px;
    margin: 0px;
    border: none;
    border-radius: 14px;
    color: darkgrey;
    background-color: inherit;
    display: inline-block;
}
.uploadFileBtn:hover { 
    background-color: darkgrey; 
    color: white;
}
.uploadFileBtn span { font-size: 19px; }

