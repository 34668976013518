/** Output table **/
.outputList {
    display: inline-block;
    vertical-align: top;
    overflow: auto;
    height: calc(100% - 54px);
}

.outputList table {
    border-collapse: collapse;
    width: 100%;
}

.outputList table tr:nth-child(2n + 1), .header2D { background-color: #f8f8f8; }
.outputList table tr:nth-child(2n), .header3D {     background-color: #ececec; }
.outputList table tr th { padding: 0 10px; }
.outputList table tr td { padding: 10px;   }
.outputList table tr td:last-child { text-align: right; }

.outputListView h1 { 
    margin: 0; 
    margin-bottom: 10px;
    text-align: center;
}
.outputListView h1 .material-symbols-outlined {
    font-size: 30px;
    vertical-align: middle;
}
.outputListView h1 .text {
    font-size: 25px;
    vertical-align: middle;
    padding-left: 15px;
}

.Collapsible {
    margin: 5px;
    width: 310px;
    background-color: #d5e5ff;
    border-radius: 10px;
}

.Collapsible__trigger {
    background-color: var(--accent-color);
    color: white;
    padding: 10px;
    padding-right: 40px;
    display: block;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
}

.Collapsible__trigger::after {
    font-family: 'FontAwesome';
    content: '▲';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
}

.Collapsible__trigger.is-open::after {
    transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
}