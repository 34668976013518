.userView {
    background-color: white;
    max-width: 850px;
    margin: auto;
    padding: 10px;
    padding-top: 0px;
    height: calc(100vh - 75px);
    box-sizing: border-box;
    /*min-width: 800px;*/
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: auto;
    border: 10px solid transparent;
    border-radius: 20px;
}

.userCreateView {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.userPage {
    max-width: 800px;
    padding: 0 min(100px, 9%);
    margin: auto;
    overflow-y: auto;
    height: 100%;
}

.userPage table {
    border-collapse: collapse;
    width: 550px;
}

.userCreateView .userPage table {
    border-collapse: collapse;
    width: 650px;
}

.userPage table h1 .material-symbols-outlined {
    font-size: 30px;
    vertical-align: middle;
}
.userPage table h1 .text {
    font-size: 25px;
    vertical-align: middle;
    padding-left: 15px;
}

.userPage table tr { width: 100%; }
.userPage table .dataRow { background-color: #dbe8ff; }
.userPage table .dataRow td:first-child {
    font-weight: bold;
    margin: 5px 0;
    text-align: right;
    padding: 10px;
    width: 30%;
    border-top-left-radius:    7px;
    border-bottom-left-radius: 7px;
}
.userPage table .dataRow td:not(:first-child, .emptyColumn, .spaceColumn) {
    height: 40px;
    width: 35%;
    border-top-right-radius:    7px;
    border-bottom-right-radius: 7px;
    padding: 0;
}
.userPage table .dataRow .spaceColumn, .emptyColumn { background-color: white; }
.userPage table .dataRow .spaceColumn { width: 10px; }
.userPage table .dataRow .emptyColumn { width: 35%;  }
.userPage table .dataRow .errorMsg {
    width: calc(30% - 22px);
    height: 40px;
}
.userPage table .dataRow .errorMsg span {
    width: calc(100% - 5px);
    height: calc(100% - 2px);
    color: red;
    background: rgb(255, 201, 201);
    border-radius: 5px;
    border: 1px solid red;
    display: inline-flex;
    align-items: center;
    padding-left: 5px;
}
.userPage table .spaceRow { height: 10px; }

.editableInput {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    height: calc(100% - 2px);
    width: calc(100% - 12px);
    background: white;
    border: 1px solid darkgray;
    border-radius: 7px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.emptyInput {
    border: 1px solid red;
}

.notEditable {
    background: rgb(238, 238, 238);
    color: rgb(70, 70, 70);
}


@keyframes slideReveal {
    0% {display: none; transform: translateX(150%);}
    1% {display: block;}
    100% {transform: translateX(0%);}
}

.slideForm {
    display: block;
}

.visibleForm {
    animation-name: slideReveal;
    animation-duration: 1s;
}

.rightForm {
    display: none;
    transform: translateX(150%);
}

.leftForm {
    display: none;
    transform: translateX(-150%);
}