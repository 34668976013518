/** Options **/
.options {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    grid-template-rows: auto 200px 350px;
    gap: 10px;
    width: 100%;
    min-height: 100%;
}

.options.tablet {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 400px 250px;
}

.options.phone {
    grid-template-columns: 1fr;
    grid-template-rows: auto 200px 200px 400px 250px 200px;
}

.options > div { 
    vertical-align: top;
}
.options .optionsTable {
    border: none;
    min-height: 150px;
    border-radius: 10px;
    background: var(--accent-light);
    padding-top: 10px;
    padding-bottom: 10px;
}

.options .optionsTable:first-child {  margin-left: 0px; }
.options .optionsTable table       { margin: 0 auto; }

.optionsGroup { display: flex; }

.optionsCoordDist {  grid-column: 1 / 3;  grid-row: 1;     }
.optionsSelection {  grid-column: 1;      grid-row: 2;     }
.optionsPoints    {  grid-column: 2;      grid-row: 2;     }
.boundingboxMap   {  grid-column: 3;      grid-row: 1 / 4; }
.optionsGenerate  {  grid-column: 1;      grid-row: 3;     }
.optionsStart     {  grid-column: 2;      grid-row: 3;     }

.tablet .optionsSelection {  grid-column: 3;      grid-row: 1; }
.tablet .optionsPoints    {  grid-column: 4;      grid-row: 1; }
.tablet .boundingboxMap   {  grid-column: 1 / 5;  grid-row: 2; }

.phone .optionsCoordDist {  grid-column: 1;  grid-row: 1; }
.phone .optionsPoints    {  grid-column: 1;  grid-row: 3; }
.phone .boundingboxMap   {  grid-column: 1;  grid-row: 4; }
.phone .optionsGenerate  {  grid-column: 1;  grid-row: 5; }
.phone .optionsStart     {  grid-column: 1;  grid-row: 6; }


.mapContainer {
    width:  100%;
    height: 100%;
    border-radius: 10px;
}

.generateButton { margin: 20px; }
.cost { margin: 0 20px; }

.center { text-align: center; }
.shortInput { width: 75px; }

/** Info Popup **/
.info, .info:before, .infoSlide-content, .infoSlide-arrow {
    color: rgb(163, 125, 0);
}

.info, .infoSlide-content {
    background-color: rgb(252, 252, 171);
    border: 1px solid rgb(163, 125, 0);
    z-index: 1001 !important;
}

.info {
    padding: 5px 3px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    width: 30px;
}

.info:before {
    font-weight: bold;
    font-size: 15px;
    content: 'ⓘ';
    position: relative;
    padding: 7px;
    display: inline;
}

.alignRight { text-align: right; }

.triangleImg {
    width: 90%;
    padding: 2%;
}

td.min {
    width: 1%;
    white-space: nowrap;
}

td.right {
    text-align: right;
}

.smallText {
    font-size: 15px;
}