.App {
  min-height: 100vh;
}

.downloadPage {
  background-color: white;
  min-height: calc(100vh - 105px);
  border: 10px solid transparent;
  border-radius: 20px;
  max-width: 850px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 0px;
}

.fullPage {
  background-color: var(--background-color);
  padding-top: 65px;
  height: calc(100vh - 65px);
}