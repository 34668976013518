.contentPage {
    background-color: white;
    overflow: auto;
    height: 100vh;
    font-size: 17px;
}

.contentPage h3 {
    margin: 0;
    text-align: center;
    font-size: 45px;
    line-height: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.contentPage .intro {
    min-height: 550px;
    font-size: 25px;
    color: white;
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(#4f70aab4, #4f70aa1f), url("../images/Banner.png");
    background-size: cover;
    padding-left: 20px;
    padding-right: 20px;
}

.intro p:last-child {
    padding-bottom: 50px;
}

.contentPage hr {
    background-color: var(--accent-color);
    height: 10px;
    border: none;
    margin: 0;
    margin-top: 35px;
}

.contentPage p, 
.contentPage h4, 
.contentPage ul, 
.contentPage ol, 
/*.contentPage .img, */
.contentPage .video 
{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.contentPage ul, .contentPage ol {
    padding-left: 40px;
}

.contentPage iframe {
    padding: 10px 0;
    width: 100%;
}

.contentPage .textBlock:nth-child(odd) {
    background-color: rgb(85, 85, 85);
    color: white;
}

.contentPage .textBlock:nth-child(odd):not(.buttonLinks) a {
    color: lightgray;
    text-decoration: none;
}

.contentPage .textBlock:nth-child(even) {
    background-color: rgb(255, 255, 255);
}

.contentPage h4 {
    font-size: 35px;
    margin: 0 auto;
    padding: 20px;
    max-width: 1000px;
}

.contentPage .img img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    object-position: 70% 0;
    margin: 0;
    float: left;
    margin-bottom: 20px;
}

.contentPage .flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.contentPage .text {
    max-width: 700px;
    /*margin-left: calc((50% - 500px) + 320px);*/
}

.contentPage .text p:first-child {
    margin-top: 0;
}

.contentPage .logo img {
    width: 100%;
}

.textBlock > .logo {
    display: inline-block;
    /*margin: 20px 40px 0;*/
    width: calc(20% - 80px);
    max-width: 120px;
    padding: 40px;
    padding-left: calc(50% - 460px);
}

.textBlock > .logo ~ .logo {
    padding-left: 40px;
}

.buttonLinks a {
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--accent-color);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    line-height: normal;
    text-decoration: none;
    padding: 10px 20px;
    margin: 8px 0;
}

.buttonLinks a:hover {
    background-color: #4d6ca5;
}


.copyright {
    background-color: #2c2c2c;
    color: white;
    text-align: center;
    /*height: 75px;*/
    line-height: 25px;
}

.copyright p {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
}

.copyright a {
    color: inherit;
    text-decoration: inherit;
}

.view3d {
    /*max-width: 1000px;
    margin-left: auto;
    margin-right: auto;*/
    padding-left: calc(50vw - 500px);
}

.singleView3D {
    width: 480px;
    height: 420px;
    display: inline-block;
    padding: 0 10px;
}

.singleView3D h2 {
    margin: 0;
    padding-left: 10px;
}

.singleView3D iframe {
    width: 480px;
    height: 360px;
    padding: 10px;
}

.contentExamplePage .text {
    max-width: 700px;
    /*min-width: 700px;*/
    /*margin-left: calc((50% - 500px) + 320px);*/
}