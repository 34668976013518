.sidebar {
    position: fixed;
    left: 0;
    top: 55px;
    background-color: var(--background-color);
    min-width: 250px;
    max-width: 250px;
    height: calc(100% - 55px);
    padding: 10px 10px;
    transition: 1.0s;
    -webkit-transition: 1.0s;
    z-index: 1001;
}
.sidebar.sbClosed {
    transform: translate(-100%, 0);
    -webkit-transform: translate(-100%, 0);
}

.sbExpand {
    max-width: 100%;
}

.topRow {
    margin-top: 3px;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 15px;
}
.topRow:hover { background-color: rgb(219, 219, 219); }

.activeProject {
    background-color: #cee0ff !important;
    font-weight: bold;
}

.sbTable {
    width: 100%;
    height: calc(100% - 200px);
    overflow: auto;
}

.sbTitle {
    display: inline-block;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 66px);
}

.sbState {
    display: block;
    width: 23px;
    min-height: 5px;
    text-align: center;
    float: left;
}
.sbState span   { font-size: 17px; padding: 3px; }
.sbStateDone    { color: green; }
.sbStateRunning { color: #5392ff; animation: rotation 3s infinite linear; }

@keyframes rotation {
    from { transform: rotate(359deg);   }
    to {   transform: rotate(0deg); }
}

.sbBtn {
    float: right;
    margin-top: 1px;
}

.sbProjBtn {
    height: 23px;
    width: 23px;
    padding: 2px;
    margin: 0px;
    border: none;
    border-radius: 14px;
    color: darkgrey;
    background-color: inherit;
    display: inline-block;
}
.sbProjBtn:hover { 
    background-color: darkgrey; 
    color: white;
}
.sbProjBtn span { font-size: 19px; }

.sbRotateBtn {
    width: 40px;
    height: 40px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    float: right;
    padding: 0;
    margin-top: 30px;
    background-color: inherit;
    color: darkgray;
    transform-origin: center;
    -webkit-transform-origin: center;
}
.sbRotateBtn .material-symbols-outlined {
    font-size: 40px;
}
.sbRotateBtn:hover {
    background-color: #b9b9b9 !important;
    color: white;
}

.rotatedBtn {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.newOpenProject {
    display: inline-block;
}

.smallerText {
    font-size: 12pt;
}