.react-cookie-law-dialog {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100000;
    background-color: transparent;
}

.react-cookie-law-container {
    background-color: rgb(240, 240, 240);
    padding: 10px;
    /*border-radius: 10px;*/
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.react-cookie-law-msg {
    min-height: 32px;
    font-size: 10pt;
    font-weight: 400;
    line-height: 130%;
    padding: 10px 0px;
    color: rgb(0, 0, 0);
}

.react-cookie-law-select-pane {
    display: table-cell;
    padding: 3px 0px 10px;
}

.react-cookie-law-option-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 7px;
    margin-left: 7px;
    float: left;
}

.react-cookie-law-option-wrapper label {
    height: auto;
    width: auto;
    min-height: 14px;
    font-size: 12pt;
    color: rgb(198, 198, 198);
    display: inline-block;
    padding: 1px 0px 0px 20px;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 1;
    cursor: default;
    vertical-align: top;
}

.react-cookie-law-option-checkbox {
    position: absolute;
    top: 4px;
    left: 0px;
    width: 14px;
    height: 14px;
    z-index: 2;
    cursor: pointer;
}

.react-cookie-law-policy {
    font-size: 10pt;
    margin-left: 10px;
    color: rgb(0, 0, 0);
    text-decoration: underline;
}

.react-cookie-law-button-wrapper {
    float: right;
}

.react-cookie-law-accept-selection-btn {
    display: inline-block;
    background-color: var(--background-color);
    padding: 5px;
    min-width: 80px;
    color: rgb(240, 20, 20);
    border: 1px solid rgb(240, 20, 20);
    border-radius: 12px;
    text-decoration: none;
    font-size: 10pt;
    font-weight: 400;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

.react-cookie-law-accept-all-btn {
    display: inline-block;
    background-color: var(--accent-color);
    padding: 5px;
    min-width: 80px;
    color: var(--background-color);
    border-radius: 12px;
    text-decoration: none;
    font-size: 10pt;
    font-weight: 400;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}